<style lang="scss">
.wrapper {
  // rgb(65,53,53)
  background: rgba(32,30,46,0.9);
  --clip-size: 16px;
  clip-path: polygon(
    0 0,
    0 -29px,
    calc(100% - var(--clip-size)) 0,
    100% var(--clip-size),
    100% 100%,
    0 100%
  );
}
.CodeMirror {
  // height: 580px;
  // font-family: BlenderPro Thin;
  font-size: 16px;
  line-height: 1.5;
}
.code-editor {
  // pointer-events: none !important;
  width: 100%;
  // color: #ccc;
  font-family: BlenderPro Thin;
  font-size: 14px;
  line-height: 1.5;
  opacity: 0.9 !important;
  // padding: 5px;
}
.choice-start {
  border-left: 1px solid #4FD8E1;
  &.picked {
    border-left: 1px solid rgb(255,240,64);
  }
}
.choice-end {
  border-right: 1px solid #4FD8E1;
  &.picked {
    border-right: 1px solid rgb(255,240,64);
  }
}
.choice {
  // pointer-events: all;
  // z-index: 1000;
  cursor: pointer;
  // padding: 2px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  background: rgba(79, 216, 225, 0.03);
  // border: 1px solid #4FD8E1;
  border-top: 1px solid #4FD8E1;
  border-bottom: 1px solid #4FD8E1;
  box-sizing: border-box;
  box-shadow: inset -6px 6px 16px rgba(79, 216, 225, 0.25);
  &:hover {
    // border-top: 1px solid rgba(79, 216, 225, 0.3);
    // border-bottom: 1px solid rgba(79, 216, 225, 0.3);
    // background: rgba(79, 216, 225, 0.3);
  }
  &.picked {
    // border: 1px solid rgb(255,240,64);
    border-top: 1px solid rgb(255,240,64);
    border-bottom: 1px solid rgb(255,240,64);
    box-shadow: inset -6px 6px 16px rgba(255,240,64,0.25),
      inset 0px -6px 16px rgba(255,240,64,0.25),
      inset 6px 0px 16px rgba(255,240,64,0.25),
      inset 0px 6px 16px rgba(255,240,64,0.25);
  }
}
</style>

<template>
<div
    ref="wrapper"
    class="flex flex-row flex-wrap w-full items-start content-start wrapper"
    :style="{
      maxWidth: '700px',
    }"
  >
  <!-- question.question wrapper -->
  <div
    class="flex flex-row flex-wrap w-full items-start content-start"
    :style="{
      padding: '20px 20px'
    }"
  >
    <!-- question.question -->
    <p
      class="w-full"
      :contenteditable="mode === 'edit'"
      :style="{
        fontSize: '30px',
        fontWeight: 'bold',
        color: 'white',
        paddingBottom: '10px',
      }"
      @input="questionInput"
    >
      {{ question.question }}
    </p>
  </div>
  <!-- editor -->
  <codemirror
    ref="cm"
    class="code-editor"
    v-model="question.text"
    :options="cmOptions"
    @ready="cmReady"
    @beforeSelectionChange="codeSelectedThrottled"
  />
  <!-- footer -->
  <div
    class="flex flex-row flex-wrap w-full"
    :style="{
      padding: '0px',
      minHeight: '0px',
    }"
  >
    <slot name="footer"></slot>
    <!-- emit answer -->
    <button
      v-if="showAnswerBtn && choiceSelected"
      class="geecko-button m-5"
      :disabled="choiceSent"
      :style="{
        background: !choiceSent ? '#FFEF40' : '#7F7E8A'
      }"
      @click="choiceSent = true, $emit('answer', choiceSelected)"
    >
      {{ choiceSent ? 'Загрузка...' : 'Ответить' }}
    </button>
  </div>
</div>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material-darker.css'
import 'codemirror/mode/javascript/javascript.js'
// import 'codemirror/mode/swift/swift.js'
// import 'codemirror/mode/java'

export default {
  name: 'QuestionSnippet',
  components: {
    codemirror,
  },
  props: {
    mode: {
      type: String,
      default: 'preview'
    },
    lang: {
      type: String,
      default: 'javascript'
    },
    question: {
      type: Object,
      required: true,
    },
    showAnswerBtn: {
      type: Boolean,
      default: true,
    }
  },
  data () {
    return {
      cm: null,
      // Choice
      choiceSent: false,
      choiceSelected: null,
      // Code
      codeSelectingTimeout: null,
    }
  },
  computed: {
    cmOptions () {
      return {
        tabSize: 4,
        // mode: 'markdown',
        mode: this.lang,
        theme: 'material-darker',
        lineNumbers: true,
        line: true,
        readOnly: this.mode === 'preview',
        autofocus: this.mode === 'edit',
      }
    }
  },
  watch: {
    question: {
      deep: true,
      immediate: true,
      handler (to) {
        if (to) {
          this.choiceSent = false
          this.choiceSelected = null
        }
        if (to && this.cm) {
          this.$log('question TO', to)
          this.marksUpdate()
        }
      }
    }
  },
  methods: {
    questionInput (e) {
      this.$log('questionInput', e)
      this.question.question = e.target.innerText
    },
    cmReady (cm) {
      this.$log('cmReady', cm)
      this.cm = cm
      // Create marks from questions.choices
      this.marksUpdate()
    },
    marksUpdate (markPicked) {
      this.$log('marksUpdate', markPicked)
      // Clear all marks
      let marks = this.cm.getAllMarks()
      marks.map(m => m.clear())
      // Create all marks
      this.question.choices.map(c => {
        // Choice structure is like: [[0,0],[0,10]]
        let anchor = {line: c[0][0], ch: c[0][1]}
        let head = {line: c[1][0], ch: c[1][1]}
        // Check markPicked
        if (markPicked && markPicked[0][0] === c[0][0] && markPicked[0][1] === c[0][1]) {
          this.$log('marksUpdate markPicked!', c)
          let mark = this.markCreate(anchor, head, 'choice picked')
          this.$log('marksUpdate mark', mark)
        }
        else {
          let mark = this.markCreate(anchor, head)
          this.$log('marksUpdate mark', mark)
        }
      })
    },
    codeSelectedThrottled (e) {
      this.$log('codeSelectedThrottled')
      if (this.codeSelectingTimeout) {
        clearTimeout(this.codeSelectingTimeout)
        this.codeSelectingTimeout = null
      }
      this.codeSelectingTimeout = setTimeout(() => {
        this.$log('codeSelectedThrottled DONE')
        this.codeSelected(e)
      }, 200)
    },
    codeSelected (e) {
      this.$log('codeSelected', e)
      const range = e.doc.sel.ranges[0]
      const anchor = range.anchor
      const head = range.head
      this.$refs.cm.codemirror.getInputField().blur()
      // its click
      if (anchor.line === head.line && anchor.ch === head.ch) {
        const [mark] = this.$refs.cm.codemirror.findMarksAt(anchor)
        if (mark) {
          this.$log('mark.oid', mark.oid)
          this.$log('mark', mark)
          this.$emit('mark', mark)
          this.marksUpdate([[mark.anchor.line, mark.anchor.ch]])
          this.choiceSelected = [[mark.anchor.line, mark.anchor.ch], [mark.head.line, mark.head.ch]]
        }
        else {
          this.$log('Nothing...')
          this.marksUpdate()
          this.$emit('mark', null)
        }
      }
      // its selection, create mark
      else {
        if (this.mode !== 'edit') return
        let mark = this.markCreate(anchor, head)
        this.$emit('mark-created', mark)
        // this.$emit('mark', mark)
      }
    },
    markCreate (anchor, head, className = 'choice') {
      this.$log('markCreate', anchor, head)
      let mark = this.$refs.cm.codemirror.markText(
        anchor,
        head,
        {
          startStyle: 'choice-start',
          endStyle: 'choice-end',
          className: className,
          handleMouseEvents: true,
          readOnly: true,
          // collapsed: false,
          // atomic: false,
          // inclusiveLeft: true,
          // inclusiveRight: true,
        })
      mark.oid = Date.now().toString()
      mark.anchor = anchor
      mark.head = head
      mark.onclick = () => {
        alert('mark click')
        this.$log('mark click')
      }
      this.$log('markCreate mark', mark)
      return mark
    }
  }
}
</script>
