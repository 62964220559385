<template>
  <div class="flex flex-row flex-wrap w-full h-screen items-center content-center justify-center">
    <router-link
      to="/"
    >
      <h2 class="text-white">Not found</h2>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
}
</script>
