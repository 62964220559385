<template>
  <div
    class="flex flex-row flex-wrap w-full"
  >
    <!-- Special offer header HINT -->
    <div class="flex flex-row flex-wrap w-full p-2 justify-center" :style="{textAlign: 'center', color: 'white', padding: '100px'}">
      <h3 :style="{color: 'white'}">hello curious developer, you found that route! we've got special offer for you!</h3>
    </div>
    <!-- body -->
    <div class="flex flex-row flex-wrap w-full justify-center">
      <QuestionSnippet
        ref="qs"
        mode="edit"
        :question="question"
        :showAnswerBtn="false"
        @answer="handleAnswer"
        @mark="handleMark"
        @mark-created="handleMarkCreated"
      >
        <template v-slot:footer>
          <div
            v-if="mark"
            class="flex flex-row flex-wrap w-full items-center p-4"
          >
            <button class="p-1 rounded w-2/6 text-white" @click="choiceCancel()">Cancel</button>
            <button class="bg-red-600 p-1 rounded w-2/6 text-white" @click="choiceDelete()">Delete</button>
            <button class="bg-green-400 p-1 rounded w-2/6 text-white" @click="choiceMakeRight()">Make right</button>
          </div>
        </template>
      </QuestionSnippet>
    </div>
    <!-- footer -->
    <div class="flex flex-row flex-wrap w-full justify-center">
      <div
        class="flex flex-row flex-wrap w-full items-start content-start"
        :style="{
          maxWidth: '700px',
          paddingTop: '10px',
        }"
      >
         <!-- Question -->
         <div class="flex flex-row flex-wrap w-full justify-start pt-5">
          <button class="geecko-button--outline text-white mr-3" @click="questionCopy('question')">Copy question title</button>
          <button class="geecko-button--outline text-white mr-3" @click="questionCopy('text')">Copy question code</button>
          <button class="text-red-600" @click="clearQuestion()">Clear question</button>
        </div>
        <!-- Choices -->
        <div class="flex flex-row flex-wrap w-full justify-start pt-5">
          <button class="geecko-button--outline text-white mr-3" @click="questionCopy('choices')">Copy question choices</button>
          <button class="text-red-600" @click="clearChoices()">Clear choices</button>
          <p class="text-white w-full" :style="{fontSize: '14px'}"> {{question.choices}} </p>
        </div>
        <!-- Correct -->
        <div class="flex flex-row flex-wrap w-full justify-start pt-5">
          <button class="geecko-button--outline text-white mr-3" @click="questionCopy('correct')">Copy question correct</button>
          <button class="text-red-600" @click="clearCorrect()">Clear correct</button>
          <p class="text-white w-full" :style="{fontSize: '14px'}"> {{question.correct}} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionSnippet from "@/components/Game/QuestionSnippet.vue"

export default {
  name: 'AdminPage',
  components: {
    QuestionSnippet,
  },
  data () {
    return {
      question: {
        question: 'Try your javascript',
        text: 'var cursor = this.instance.getSearchCursor(val);',
        choices: [[[0,0],[0,3]]],
        correct: []
      },
      questionText: '',
      mark: null,
    }
  },
  watch: {
    question: {
      deep: true,
      immediate: false,
      handler (to) {
        if (to) {
          this.$log('question TO', to)
          localStorage.setItem('admin_question', JSON.stringify(to))
          // if (this.$refs.qs) this.questionText = this.$refs.qs.cm.getValue()
        }
      }
    }
  },
  methods: {
    clearChoices () {
      this.$log('clearChoices')
      this.question.choices = []
    },
    clearCorrect () {
      this.$log('clearCorrect')
      this.question.correct = []
    },
    clearQuestion () {
      this.$log('clearQuestion')
      this.question = {
        question: '',
        text: '',
        choices: [],
        correct: []
      }
    },
    choiceCancel () {
      this.$log('choiceCancel')
      this.$refs.qs.marksUpdate()
      this.mark = null
    },
    choiceDelete () {
      this.$log('choiceDelete')
      this.question.choices = this.question.choices.filter(c => {
        this.$log('choiceDelete', c[0][0], c[0][1])
        this.$log('choiceDelete', this.mark.anchor.line, this.mark.anchor.ch)
        this.$log('choiceDelete ===============================')
        if (c[0][0] === this.mark.anchor.line) {
          if (c[0][1] === this.mark.anchor.ch) {
            return false
          }
          else {
            return true
          }
        }
        else {
          return true
        }
        // return c[0][0] !== this.mark.anchor.line && c[0][1] !== this.mark.anchor.ch
      })
      this.mark = null
    },
    choiceMakeRight () {
      this.$log('choiceMakeRight')
      let choiceInput = [[this.mark.anchor.line, this.mark.anchor.ch], [this.mark.head.line, this.mark.head.ch]]
      this.question.correct = [choiceInput]
      this.mark = null
    },
    handleMark (mark) {
      this.$log('handleMark', mark)
      this.mark = mark
    },
    handleMarkCreated (mark) {
      this.$log('handleMarkCreated', mark)
      let choiceInput = [[mark.anchor.line, mark.anchor.ch], [mark.head.line, mark.head.ch]]
      this.question.choices.push(choiceInput)
    },
    handleAnswer (e) {
      this.$log('handleAnswer', e)
    },
    questionCopy (part) {
      this.$log('questionCopy', part)
      if (part === 'text') {
        this.clipboardWrite(JSON.stringify(this.$refs.qs.cm.getValue()))
      }
      // else if (part === 'title') {
      //   this.clipboardWrite(this.question.title)
      // }
      else {
        this.clipboardWrite(JSON.stringify(this.question[part]))
      }
    },
    clipboardWrite (val, message) {
      this.$log('clipboardWrite', val)
      navigator.permissions.query({name: 'clipboard-write'}).then(async (result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          await navigator.clipboard.writeText(val)
          if (message) alert(message)
        }
      })
    },
  },
  created () {
    this.$log('created')
    let question = localStorage.getItem('admin_question')
    if (question) {
      this.question = JSON.parse(question)
    }
  },
  mounted () {
    this.$log('mounted')
  },
  beforeDestroy () {
    this.$log('beforeDestroy')
  }
}
</script>
