var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",staticClass:"flex flex-row flex-wrap w-full items-start content-start wrapper",style:({
      maxWidth: '700px',
    })},[_c('div',{staticClass:"flex flex-row flex-wrap w-full items-start content-start",style:({
      padding: '20px 20px'
    })},[_c('p',{staticClass:"w-full",style:({
        fontSize: '30px',
        fontWeight: 'bold',
        color: 'white',
        paddingBottom: '10px',
      }),attrs:{"contenteditable":_vm.mode === 'edit'},on:{"input":_vm.questionInput}},[_vm._v(" "+_vm._s(_vm.question.question)+" ")])]),_c('codemirror',{ref:"cm",staticClass:"code-editor",attrs:{"options":_vm.cmOptions},on:{"ready":_vm.cmReady,"beforeSelectionChange":_vm.codeSelectedThrottled},model:{value:(_vm.question.text),callback:function ($$v) {_vm.$set(_vm.question, "text", $$v)},expression:"question.text"}}),_c('div',{staticClass:"flex flex-row flex-wrap w-full",style:({
      padding: '0px',
      minHeight: '0px',
    })},[_vm._t("footer"),(_vm.showAnswerBtn && _vm.choiceSelected)?_c('button',{staticClass:"geecko-button m-5",style:({
        background: !_vm.choiceSent ? '#FFEF40' : '#7F7E8A'
      }),attrs:{"disabled":_vm.choiceSent},on:{"click":function($event){_vm.choiceSent = true, _vm.$emit('answer', _vm.choiceSelected)}}},[_vm._v(" "+_vm._s(_vm.choiceSent ? 'Загрузка...' : 'Ответить')+" ")]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }